module.exports = [{
      plugin: require('/home/circleci/repo/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"FRT - Ferencz Racing Technology","short_name":"FRT","start_url":"/","background_color":"#fff","theme_color":"#e74c3c","display":"minimal-ui","icon":"src/assets/images/website-icon.png"},
    },{
      plugin: require('/home/circleci/repo/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Source+Sans+Pro:300,300i,600,600i"]}},
    },{
      plugin: require('/home/circleci/repo/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/circleci/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
