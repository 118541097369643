// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-igorko-redirect-js": () => import("/home/circleci/repo/.cache/@igorko/redirect.js" /* webpackChunkName: "component---cache-igorko-redirect-js" */),
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/home/circleci/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/home/circleci/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-elements-js": () => import("/home/circleci/repo/src/pages/elements.js" /* webpackChunkName: "component---src-pages-elements-js" */),
  "component---src-pages-galeria-js": () => import("/home/circleci/repo/src/pages/galeria.js" /* webpackChunkName: "component---src-pages-galeria-js" */),
  "component---src-pages-generic-js": () => import("/home/circleci/repo/src/pages/generic.js" /* webpackChunkName: "component---src-pages-generic-js" */),
  "component---src-pages-index-js": () => import("/home/circleci/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inziniering-js": () => import("/home/circleci/repo/src/pages/inziniering.js" /* webpackChunkName: "component---src-pages-inziniering-js" */),
  "component---src-pages-kontakt-js": () => import("/home/circleci/repo/src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-landing-js": () => import("/home/circleci/repo/src/pages/landing.js" /* webpackChunkName: "component---src-pages-landing-js" */),
  "component---src-pages-model-sample-js": () => import("/home/circleci/repo/src/pages/model-sample.js" /* webpackChunkName: "component---src-pages-model-sample-js" */),
  "component---src-pages-predstavenie-js": () => import("/home/circleci/repo/src/pages/predstavenie.js" /* webpackChunkName: "component---src-pages-predstavenie-js" */),
  "component---src-pages-produkty-frt-65-js": () => import("/home/circleci/repo/src/pages/produkty/frt-65.js" /* webpackChunkName: "component---src-pages-produkty-frt-65-js" */),
  "component---src-pages-produkty-index-js": () => import("/home/circleci/repo/src/pages/produkty/index.js" /* webpackChunkName: "component---src-pages-produkty-index-js" */),
  "component---src-pages-technologia-js": () => import("/home/circleci/repo/src/pages/technologia.js" /* webpackChunkName: "component---src-pages-technologia-js" */)
}

